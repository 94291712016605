<template>
    <v-container class="grey lighten-5">
        <v-row no-gutters>
            <v-col cols="2">
                <v-card class="pa-2" outlined tile>
                    姓名: {{ personalInformation.name }}
                </v-card>
            </v-col>
            <v-col cols="2">
                <v-card class="pa-2" outlined tile>
                    性别: <span v-html="personalInformation.sex === 0 ? '男' : '女'"></span>
                </v-card>
            </v-col>
            <v-col cols="2">
                <v-card class="pa-2" outlined tile>
                    生日: <span
                    v-html="personalInformation.birthday ? personalInformation.birthday.substring(0, 10) : personalInformation.birthday"></span>
                </v-card>
            </v-col>
            <v-col cols="2">
                <v-card class="pa-2" outlined tile>
                    国籍: {{ personalInformation.citizenship }}
                </v-card>
            </v-col>
            <v-col cols="2" v-if="personalInformation.citizenship === '中国'">
                <v-card class="pa-2" outlined tile>
                    籍贯: {{ personalInformation.birthplace }}
                </v-card>
            </v-col>
            <v-col cols="2" v-if="personalInformation.citizenship === '中国'">
                <v-card class="pa-2" outlined tile>
                    民族: {{ personalInformation.nation }}
                </v-card>
            </v-col>
            <v-col cols="4">
                <v-card class="pa-2" outlined tile>
                    邮箱: {{ personalInformation.email }}
                </v-card>
            </v-col>
            <v-col cols="2">
                <v-card class="pa-2" outlined tile>
                    证件类型: {{ personalInformation.identityType }}
                </v-card>
            </v-col>
            <v-col cols="3">
                <v-card class="pa-2" outlined tile>
                    证件号码: {{ personalInformation.identityCode }}
                </v-card>
            </v-col>
            <v-col cols="3">
                <v-card class="pa-2" outlined tile>
                    人才类型: {{ personalInformation.type }}
                </v-card>
            </v-col>
            <v-col cols="6">
                <v-card class="pa-2" outlined tile>
                    证件有效开始日期:
                    <span
                        v-html="personalInformation.startEffectiveDate ? personalInformation.startEffectiveDate.substring(0, 10) : personalInformation.startEffectiveDate"></span>
                </v-card>
            </v-col>
            <v-col cols="6">
                <v-card class="pa-2" outlined tile>
                    证件有效结束日期:
                    <span
                        v-html="personalInformation.endEffectiveDate ? personalInformation.endEffectiveDate.substring(0, 10) : personalInformation.endEffectiveDate"></span>
                </v-card>
            </v-col>
        </v-row>

        <v-row no-gutters class="mt-4">
            <v-col cols="2">
                <v-card class="pa-2" outlined tile>
                    是否买房: <span v-html="personalInformation.buyHouse === false ? '否' : '是'"></span>
                </v-card>
            </v-col>
            <v-col cols="4">
                <v-card class="pa-2" outlined tile>
                    是否在中心城区有房: <span v-html="personalInformation.hasHouse === false ? '否' : '是'"></span><span
                    class="ml-4">{{ personalInformation.block }}</span>
                </v-card>
            </v-col>
            <v-col cols="3">
                <v-card class="pa-2" outlined tile>
                    是否在赣州经开区缴纳社保: <span v-html="personalInformation.hasSocialSecurity === false ? '否' : '是'"></span>
                </v-card>
            </v-col>
            <v-col cols="3">
                <v-card class="pa-2" outlined tile>
                    缴纳社保时间: <span
                    v-html="personalInformation.socialSecurityPaymentTime ? personalInformation.socialSecurityPaymentTime.substring(0, 10) : personalInformation.socialSecurityPaymentTime"></span>
                </v-card>
            </v-col>
            <v-col cols="12">
                <v-card class="pa-2" outlined tile>
                    户籍地址: {{ personalInformation.addressDeposit }}
                </v-card>
            </v-col>
        </v-row>

        <v-row no-gutters class="mt-4">
            <v-col cols="3">
                <v-card class="pa-2" outlined tile>
                    最高学历: {{ educations.academic }}
                </v-card>
            </v-col>
            <v-col cols="3">
                <v-card class="pa-2" outlined tile>
                    毕业学校: {{ educations.school }}
                </v-card>
            </v-col>
            <v-col cols="3">
                <v-card class="pa-2" outlined tile>
                    专业: {{ educations.major }}
                </v-card>
            </v-col>
            <v-col cols="3">
                <v-card class="pa-2" outlined tile>
                    学制: {{ educations.schoolSystem }}
                </v-card>
            </v-col>
            <v-col cols="3" v-if="!educations.isStudyAbroad">
                <v-card class="pa-2" outlined tile>
                    毕业证书编号: {{ educations.diplomaCode }}
                </v-card>
            </v-col>
            <v-col cols="3">
                <v-card class="pa-2" outlined tile>
                    毕业日期: <span
                    v-html="educations.graduateTime ? educations.graduateTime.substring(0, 10) : educations.graduateTime"></span>
                </v-card>
            </v-col>
            <v-col cols="3">
                <v-card class="pa-2" outlined tile>
                    是否国外学历: <span v-html="educations.isStudyAbroad === true ? '是' : '否'"></span>
                </v-card>
            </v-col>
            <v-col cols="3" v-if="!educations.isStudyAbroad">
                <v-card class="pa-2" outlined tile>
                    是否全日制: <span v-html="educations.type === '全日制' ? '是' : '否'"></span>
                </v-card>
            </v-col>
        </v-row>

        <v-row no-gutters v-for="(item,index) in talentSecondEducation" :key="index">
            <v-col cols="3">
                <v-card class="pa-2" outlined tile>
                    学历: {{ item.academic }}
                </v-card>
            </v-col>
            <v-col cols="3">
                <v-card class="pa-2" outlined tile>
                    毕业学校: {{ item.school }}
                </v-card>
            </v-col>
            <v-col cols="3">
                <v-card class="pa-2" outlined tile>
                    专业: {{ item.major }}
                </v-card>
            </v-col>
            <v-col cols="3">
                <v-card class="pa-2" outlined tile>
                    学制: {{ item.schoolSystem }}
                </v-card>
            </v-col>
            <v-col cols="3" v-if="!item.isStudyAbroad">
                <v-card class="pa-2" outlined tile>
                    毕业证书编号: {{ item.diplomaCode }}
                </v-card>
            </v-col>
            <v-col cols="3">
                <v-card class="pa-2" outlined tile>
                    毕业日期: <span
                    v-html="item.graduateTime ? item.graduateTime.substring(0, 10) : item.graduateTime"></span>
                </v-card>
            </v-col>
            <v-col cols="3">
                <v-card class="pa-2" outlined tile>
                    是否国外学历: <span v-html="item.isStudyAbroad === true ? '是' : '否'"></span>
                </v-card>
            </v-col>
            <v-col cols="3" v-if="!item.isStudyAbroad">
                <v-card class="pa-2" outlined tile>
                    是否全日制: <span v-html="item.type === '全日制' ? '是' : '否'"></span>
                </v-card>
            </v-col>
        </v-row>

        <v-row no-gutters class="mt-4 mb-4">
            <v-col cols="4">
                <v-card class="pa-2" outlined tile>
                    目前供职单位: {{ personalInformation.enterpriseName }}
                </v-card>
            </v-col>
            <v-col cols="4">
                <v-card class="pa-2" outlined tile>
                    所在单位职务: {{ personalInformation.position }}
                </v-card>
            </v-col>
            <v-col cols="4">
                <v-card class="pa-2" outlined tile>
                    职称类别: {{ personalInformation.value }}
                </v-card>
            </v-col>
            <v-col cols="4">
                <v-card class="pa-2" outlined tile>
                    入职时间: <span
                    v-html="personalInformation.hireDate ? personalInformation.hireDate.substring(0, 10) : personalInformation.hireDate"></span>
                </v-card>
            </v-col>
            <v-col cols="4">
                <v-card class="pa-2" outlined tile>
                    是否省外引进: {{ personalInformation.rankLevel }}
                </v-card>
            </v-col>
            <v-col cols="4">
                <v-card class="pa-2" outlined tile>
                    从事专业领域: {{ personalInformation.skillCategory }}
                </v-card>
            </v-col>
        </v-row>

        <v-row no-gutters v-for="(item, index) in honors" :key="index">
            <v-col cols="3">
                <v-card class="pa-2" outlined tile>
                    所获荣誉名称: {{ item.honorName }}
                </v-card>
            </v-col>
            <v-col cols="3">
                <v-card class="pa-2" outlined tile>
                    实施部门: {{ item.implementationDepartment }}
                </v-card>
            </v-col>
            <v-col cols="3">
                <v-card class="pa-2" outlined tile>
                    获奖年月:<span
                        v-html="item.awardYear ? item.awardYear.substring(0, 10) : item.awardYear"></span>
                </v-card>
            </v-col>
            <v-col cols="3">
                <v-card class="pa-2" outlined tile>
                    所获奖级别: {{ item.rewardLevel }}
                </v-card>
            </v-col>
        </v-row>

        <div class="mt-4"></div>

        <v-row no-gutters v-for="item in resumes" :key="item.id">
            <v-col cols="4">
                <v-card class="pa-2" outlined tile>
                    工作单位名称: {{ item.companyName }}
                </v-card>
            </v-col>
            <v-col cols="4">
                <v-card class="pa-2" outlined tile>
                    起止年月: <span>{{ item.startDate | formatDate }} 至{{ item.endDate | formatDate }} </span>
                </v-card>
            </v-col>
            <v-col cols="4">
                <v-card class="pa-2" outlined tile>
                    职务: {{ item.jobTitle }}
                </v-card>
            </v-col>
        </v-row>

        <v-row no-gutters class="mt-4">
            <v-col>
                <v-card class="pa-2" outlined tile>
                    个人简历: {{ personalInformation.resume }}
                </v-card>
            </v-col>
        </v-row>

        <v-row class="file">
            <v-col cols="3" class="left" v-for="item in file" :key="item.id">
                <span @click="getSeePhoto(item)">{{ item.memo }}</span>
            </v-col>
            <v-col cols="3" class="left" v-for="item in honorsFile" :key="item.id">
                <span @click="getSeePhoto(item)">{{ item.memo }}</span>
            </v-col>
            <v-col cols="3" class="left" v-for="item in talentSecondEducationFile" :key="item.id">
                <span @click="getSeePhoto(item)">{{ item.memo }}</span>
            </v-col>
        </v-row>

        <v-dialog v-model="seePhotoDialog" scrollable width="70%">
            <v-card>
                <v-card-title>{{ photoTile }}</v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <div id="demo"></div>
                    <v-img :src="photoPath" contain aspect-ratio="2" alt="" v-if="photoPath"/>
                    <!-- <pdf
                    ref="pdf"
                    v-for="i in numPages" 
                    :key="i"  
                    :src="pdf" 
                    :page="i"
                    ></pdf> -->
                    <iframe :src="pdf" frameborder="0" style="width: 100%; height: 700px" v-if="pdf"></iframe>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="blue darken-1" text @click="seePhotoDialog = false">关闭</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        
    </v-container>
</template>

<script>
import url from "../../utils/config.js"
// import pdf from 'vue-pdf'
export default {
    name: "preview",
    props: {item: Object},
    // components:{
    //     pdf
    // },

    data() {
        return {
            title: "最高学历备案表",
            photoTile: "预览图片", // 图片种类
            seePhotoDialog: false,
            pdf:'',
            numPages: null, // pdf 总页数
            /*
            个人基本信息
             */
            personalInformation: {
                name: "", //姓名
                sex: "",  // 性别
                birthday: "",  //生日
                citizenship: "", // 国籍
                nation: "", // 民族
                birthplace: "", // 籍贯
                email: "", // 邮箱
                identityType: "", // 证件类型
                identityCode: "", // 证件号码
                startEffectiveDate: "", // 有效证件开始日期
                endEffectiveDate: "", // 有效证件结束日期
                type: "", // 人才类型
                buyHouse: "", // 是否买房
                hasHouse: "", // 是否在中心城区有房
                hasSocialSecurity: "", // 是否在赣州经开区缴纳社保
                socialSecurityPaymentTime: "", // 缴纳社保时间
                enterpriseName: "", // 目前供职单位
                position: "", // 所在单位职务
                value: "", //职称类别
                hireDate: "", // 入职时间
                addressDetails: "", // 户籍地址
                resume: "", // 个人简历
            },
            /*
            个人学历
             */
            educations: {
                academic: "", //  最高学历
                school: "", //毕业学校
                major: "", //专业
                graduateTime: "", //毕业日期
                diplomaCode: "", //毕业证书编号
                isStudyAbroad: false, // 是否国外学历
                schoolSystem: 0, // 学制
                dayType: "", // 是否全日制
            },
            talentSecondEducation: [], // 更多的学历
            talentSecondEducationFile: [],
            /*
            所获荣誉
             */
            honors: [],
            honorsFile: [],
            /*
               工作履历
               */
            resumes: [],
            /*
            所有的文件
             */
            file: [],
            photoPath: "", // 照片路径
            url: ""
        }
    },
    
    methods: {
        getTableData() {
            console.log(this.item)
            let information = this.item
            this.personalInformation = information;
            this.educations = information.educations;
            this.talentSecondEducation = information.talentSecondEducation;
            this.honors = information.honors;
            this.resumes = information.resumes;
            this.file = information.allFile
            this.personalInformation.addressDetails = information.addressDetails;
            this.honorsFile = [];
            this.talentSecondEducationFile = [];
            this.talentSecondEducation.forEach(item => {
                item.files.forEach(fileItem => {
                    this.talentSecondEducationFile.push(fileItem)
                })
            })
            this.honors.forEach(item => {
                item.files.forEach(fileItem => {
                    this.honorsFile.push(fileItem)
                })
            })
        },

        getSeePhoto(item) {
            console.log(item)
            let pdfFile = item.path.split(".")
            if (pdfFile[1] === "pdf") {
                console.log(item.type)
                this.seePhotoDialog = true;
                //window.open(url.url + '/file/object/preview?path=' + item.path,"_blank")
                this.pdf = url.url + '/file/object/preview?path=' + item.path
                this.photoTile = 'PDF预览'
                this.photoPath = ''
            }else {
                this.photoPath = url.url + '/file/object?path=' + item.path;
                this.seePhotoDialog = true;
                this.pdf = ""
                this.photoTile = '图片预览'
            }


        }
    },
    created() {
        this.getTableData();
    }
}
</script>

<style scoped lang="less">
.clear {
    clear: both;
}

.pa-2 {
    text-align: left;
}

.left {
    text-align: left;
}

.file span {
    margin-top: 24px;
    text-align: center;
    border: 1px solid #64B5F6;
    color: #FFFFFF;
    background-color: #64B5F6;
    border-radius: 4px;
    display: inline-block;
    width: 80%;
    height: 32px;
    line-height: 32px;
    cursor: pointer;
}
</style>