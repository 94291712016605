<!--认证管理-->
<template>
	<div>
		<el-card class="box-card" v-show="!isMobile">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item><a @click="request">首页</a></el-breadcrumb-item>
				<el-breadcrumb-item>认证管理</el-breadcrumb-item>
			</el-breadcrumb>
		</el-card>
		<div v-show="isMobile" class="breadcrumbandtab">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item><a @click="request">首页</a></el-breadcrumb-item>
				<el-breadcrumb-item>认证管理</el-breadcrumb-item>
			</el-breadcrumb>
			<el-button @click="goHome()" size="mini" type="text" v-show="isMobile" style="color:#2196f3;position: absolute;top: 0;right: 0;">回到首页</el-button>
			<ul class="tab" v-show="isMobile">
				<li @click="settab('user1')" :class="tabName == 'user1' ? 'on' : ''">
					个人信息
				</li>

				<li @click="settab('academic')" :class="tabName == 'academic' ? 'on' : ''">
					学历信息
				</li>
				<li @click="settab('enterprise')" :class="tabName == 'enterprise' ? 'on' : ''">
					单位信息
				</li>
			</ul>
		</div>
		<div v-show="isMobile">
			<div class="hr-10"></div>
			<div class="hr-10"></div>
			<div class="hr-10"></div>
			<div class="hr-10"></div>
		</div>
		<div class="hr-10"></div>

		<div>
			<el-form ref="form" label-position="left" :model="form" :rules="rules" v-loading.fullscreen.lock="fullscreenLoading">
				<el-card v-show="isMobile ? tabName == 'user1' : true">
					<el-row>
						<el-col :span="16" :class="isMobile ? 'Mobilecol' : ''">
							<el-row>
								<el-col :span="12">
									<el-form-item label="姓名" prop="name">
										<el-input style="width: 90%" v-model.trim="form.name" placeholder="姓名"></el-input>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label="性别">
										<el-radio-group v-model="form.sex" @change="testPash('sex', form.sex)">
											<el-radio :label="0">男</el-radio>
											<el-radio :label="1">女</el-radio>
										</el-radio-group>
									</el-form-item>
								</el-col>
							</el-row>

							<el-row>
								<el-col :span="12">
									<el-form-item label="生日" prop="birthday">
										<el-date-picker @blur="testPash('birthday', form.birthday)" :picker-options="pickerOptions" v-model="form.birthday" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"></el-date-picker>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label="国籍" prop="citizenship">
										<el-select @change="testPash('citizenship', form.citizenship)" v-model="form.citizenship" placeholder="请选择" filterable>
											<el-option v-for="item in nationality" :key="item.name" :value="item.name"> </el-option>
										</el-select>
									</el-form-item>
								</el-col>
							</el-row>

							<el-row v-if="form.citizenship && form.citizenship === '中国'">
								<el-col :span="12">
									<el-form-item label="民族" prop="nation">
										<el-select @change="testPash('nation', form.nation)" v-model="form.nation" placeholder="请选择" filterable>
											<el-option v-for="item in nation" :key="item.nation" :label="item.label" :value="item.nation"> </el-option>
										</el-select>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label="籍贯" prop="birthplace">
										<el-input @blur="testPash('birthplace', form.birthplace)" style="width: 90%" v-model.trim="form.birthplace" placeholder="省名+县名，如：江西安远"></el-input>
									</el-form-item>
								</el-col>
							</el-row>

							<el-row>
								<el-col :span="12">
									<el-form-item label="证件类型" prop="identityType">
										<el-input disabled style="width: 90%" v-model.trim="form.identityType"></el-input>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label="证件号码" prop="identityCode">
										<el-input :disabled="form.identityType === '身份证'" style="width: 90%" v-model.trim="form.identityCode" @blur="isIdentityCode(form.identityCode)" :style="IsCode ? 'border: 1px #dc6e6e solid;' : ''"></el-input>
									</el-form-item>
								</el-col>
							</el-row>

							<el-row>
								<el-col :span="12">
									<el-form-item label="证件有效起始日期" prop="startEffectiveDate">
										<el-date-picker @blur="testPash('startEffectiveDate', form.startEffectiveDate)" v-model="form.startEffectiveDate" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"></el-date-picker>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label="证件有效结束日期" prop="endEffectiveDate">
										<el-date-picker @blur="testPash('endEffectiveDate', form.endEffectiveDate)" v-model="form.endEffectiveDate" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"></el-date-picker>
									</el-form-item>
								</el-col>
							</el-row>

							<el-row>
								<el-col :span="12">
									<el-form-item label="邮箱" prop="email">
										<el-input @blur="testPash('email', form.email)" style="width: 90%" v-model.trim="form.email"></el-input>
									</el-form-item>
								</el-col>
								<el-col :span="6">
									<el-form-item label="人才类型">
										<el-select @change="testPash('type', form.type)" v-model="form.type" placeholder="请选择" filterable>
											<el-option v-for="item in typesTalents" :key="item.name" :label="item.name" :value="item.name"> </el-option>
										</el-select>
										<div class="redText">* 请参照“人才目录等级说明”填写</div>
									</el-form-item>
								</el-col>

								<el-col :span="6">
									<el-form-item style="margin-top: 48px">
										<div class="shuoming" @click="catalog = !catalog">
											<i class="el-icon-s-order"></i>
											人才目录等级说明
										</div>
									</el-form-item>
								</el-col>
							</el-row>
						</el-col>

						<el-col :span="7" :class="isMobile ? 'Mobilecol' : ''" :offset="1">
							<el-row>
								<el-col>
									<el-card shadow="never">
										<div
											style="
                      height: 1px;
                      width: 88%;
                      background-color: #e4e4e4;
                      position: absolute;
                      top: 48px;
                    "
										></div>
										<el-form-item style="margin: -16px 0 -12px -12px" class="uploadfic">
											<el-upload class="upload-demo" :action="host + '/file'" :on-preview="handlePreview" :on-remove="handleRemove" :before-upload="beforeAvatarUpload" :before-remove="beforeRemove" :on-success="uploadFile" :on-exceed="handleExceed" :headers="importHeaders" :data="{ memo: fileLabel }" :limit="1" accept=".png,.jpg,.jpeg" :file-list="typesTalentsFile">
												<div style="margin-left: 0; text-align: left">
													<el-button size="small" round :disabled="isUploadFile" @click="setLabel('人才类型附件')" style="color: #0c94ea">点击上传 </el-button>
												</div>
											</el-upload>
											<div style="float: left; text-align: left; margin-left: 16px">
												<div><span style="color: red">* </span>人才类型证书附件：</div>
												<div style="float: left;margin-top: -12px;font-size: 12px;">
													<span style="color: red">.png,.jpg,.jpeg格式图片 且不超过5mb </span>
												</div>
											</div>
										</el-form-item>
									</el-card>
								</el-col>
							</el-row>

							<div class="hr-10"></div>

							<el-row>
								<el-col>
									<el-card shadow="never">
										<div
											style="
                      height: 1px;
                      width: 88%;
                      background-color: #e4e4e4;
                      position: absolute;
                      top: 48px;
                    "
										></div>
										<el-form-item style="margin: -16px 0 -12px -12px" class="uploadfic">
											<el-upload class="upload-demo" :action="host + '/file'" :on-preview="handlePreview" :on-remove="handleRemove" :before-upload="beforeAvatarUpload" :before-remove="beforeRemove" :on-success="uploadFile" :headers="importHeaders" :limit="1" accept=".png,.jpg,.jpeg" :on-exceed="handleExceed" :file-list="bareheadedWorkPhotoFile">
												<div style="margin-left: 40px; text-align: left">
													<el-button size="small" round :disabled="isUploadFile" @click="setLabel('免冠工作照')" style="color: #0c94ea">点击上传 </el-button>
												</div>
											</el-upload>
											<div style="float: left; text-align: left; margin-left: 16px">
												<div><span style="color: red">* </span>免冠工作照：</div>
												<div
													style="
                            float: left;
                            margin-top: -12px;
                            font-size: 12px;
                          "
												>
													<span style="color: red">.png,.jpg,.jpeg格式图片 且不超过5mb</span>
												</div>
											</div>
										</el-form-item>
									</el-card>
								</el-col>
							</el-row>

							<div class="hr-10"></div>

							<el-row>
								<el-col>
									<el-card shadow="never">
										<div
											style="
                      height: 1px;
                      width: 88%;
                      background-color: #e4e4e4;
                      position: absolute;
                      top: 48px;
                    "
										></div>
										<el-form-item style="margin: -16px 0 -12px -12px" class="uploadfic">
											<el-upload class="upload-demo" :action="host + '/file'" :on-preview="handlePreview" :on-remove="handleRemove" :before-upload="beforeAvatarUpload" :before-remove="beforeRemove" :on-success="uploadFile" :headers="importHeaders" :limit="2" accept=".png,.jpg,.jpeg" :on-exceed="handleExceed" :file-list="IDFile">
												<div style="margin-left: 40px; text-align: left">
													<el-button size="small" round :disabled="isUploadFile" @click="setLabel('身份证')" style="color: #0c94ea">点击上传 </el-button>
												</div>
											</el-upload>
											<div style="float: left; text-align: left; margin-left: 16px">
												<div><span style="color: red">* </span>身份证/护照(横向扫描，正、反面分开上传)：</div>
												中国国籍上传身份证，外国国籍上传护照

												<div
													style="
                            margin-top: -12px;
                            font-size: 12px;
                          "
												>
													<span style="color: red">.png,.jpg,.jpeg格式图片 且不超过5mb</span>
												</div>
											</div>
										</el-form-item>
									</el-card>
								</el-col>
							</el-row>
						</el-col>
					</el-row>
				</el-card>

				<div class="hr-20" v-show="isMobile ? tabName == 'user1' : true"></div>

				<el-card v-show="isMobile ? tabName == 'user1' : true">
					<el-row>
						<el-col :span="16" :class="isMobile ? 'Mobilecol' : ''">
							<el-row>
								<el-col :span="12">
									<el-form-item label="是否买房">
										<el-radio-group v-model="form.buyHouse" @change="testPash('buyHouse', form.buyHouse)">
											<el-radio :label="true">是</el-radio>
											<el-radio :label="false">否</el-radio>
										</el-radio-group>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label="是否在中心城区有房">
										<el-radio-group v-model="form.hasHouse" prop="hasHouse">
											<el-radio :label="true">是</el-radio>
											<el-radio :label="false">否</el-radio>
										</el-radio-group>
										<el-select v-model="block" placeholder="请选择" filterable style="padding: 0 24px" v-if="form.hasHouse">
											<el-option v-for="item in opBack" :key="item.block" :label="item.label" :value="item.block"> </el-option>
										</el-select>
									</el-form-item>
								</el-col>
							</el-row>

							<el-row>
								<el-col :span="12">
									<el-form-item label="是否在赣州经开区缴纳社保" prop="hasSocialSecurity">
										<el-radio-group v-model="form.hasSocialSecurity" @change="testPash('hasSocialSecurity', form.hasSocialSecurity)">
											<el-radio :label="true">是</el-radio>
											<el-radio :label="false">否</el-radio>
										</el-radio-group>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label="缴纳社保时间" prop="socialSecurityPaymentTime">
										<el-date-picker @change="testPash('socialSecurityPaymentTime', form.socialSecurityPaymentTime)" :picker-options="pickerOptions" v-model="form.socialSecurityPaymentTime" type="month" value-format="yyyy-MM-dd"> </el-date-picker>
										<div style="font-size: 12px; color: #666666">
											请填写在经开区首次缴纳社保的时间
										</div>
									</el-form-item>
								</el-col>
							</el-row>

							<el-row v-if="isAddress">
								<el-form-item label="户籍地址" prop="addresses" class="addresses">
									<el-cascader show-message="false" :options="china" @change="handleChange" v-model="form.addresses" :placeholder="form.address" style="width: 300px; margin: 0 0 5px 0"> </el-cascader>
									<v-form ref="form" v-model="valid">
										<v-text-field v-model="form.details" placeholder="请输入详细地址" outlined dense name="accurate" :rules="detailsRules" :error-messages="rulesMessages" @input="getIsDetailsRules" />
									</v-form>
								</el-form-item>
							</el-row>
							<el-row v-else>
								<el-form-item label="户籍地址" prop="" class="addresses">
									<el-cascader show-message="false" :options="china" @change="handleChange" v-model="form.addresses" :placeholder="form.address" style="width: 300px; margin: 0 0 5px 0"> </el-cascader>
									<v-form ref="form" v-model="valid">
										<v-text-field v-model="form.details" placeholder="请输入详细地址" outlined dense name="accurate" :rules="detailsRules" :error-messages="rulesMessages" @input="getIsDetailsRules" />
									</v-form>
								</el-form-item>
							</el-row>
						</el-col>

						<el-col :span="7" :class="isMobile ? 'Mobilecol' : ''" :offset="1">
							<el-row>
								<el-col style="margin-bottom: 16px">
									<div class="shuoming" @click="template = !template"><i class="el-icon-s-order"></i>查看附件模板</div>
								</el-col>
							</el-row>

							<el-row>
								<el-col>
									<el-card shadow="never">
										<div
											style="
                                              height: 1px;
                                              width: 88%;
                                              background-color: #e4e4e4;
                                              position: absolute;
                                              top: 48px;
                                            "
										></div>
										<el-form-item style="margin: -16px 0 0 -12px" class="uploadfic">
											<el-upload class="upload-demo" :action="host + '/file'" :on-preview="handlePreview" :on-remove="handleRemove" :before-upload="beforeFileUpload" :before-remove="beforeRemove" :on-success="uploadFile" :headers="importHeaders" :limit="1" accept=".pdf" :on-exceed="handleExceed" :file-list="socialSecurityFile">
												<div style="margin-left: 40px; text-align: left">
													<el-button size="small" round :disabled="isUploadFile" @click="setLabel('社保附件')" style="color: #0c94ea">点击上传 </el-button>
												</div>
											</el-upload>
											<div style="float: left; text-align: left; margin-left: 16px">
												<div><span style="color: red">*</span> 缴纳社保附件：</div>
												<div
													style="
                                                    float: left;
                                                    margin-top: -12px;
                                                    font-size: 12px;
                                                  "
												>
													<span style="color: red">只能上传pdf文件 且不超过7mb</span>
												</div>
											</div>
										</el-form-item>
									</el-card>
								</el-col>
							</el-row>
						</el-col>
					</el-row>
				</el-card>

				<div class="hr-20" v-show="isMobile ? tabName == 'user1' : true"></div>

				<!-- 最高学历 -->
				<el-card v-show="isMobile ? tabName == 'academic' : true">
					<el-row>
						<el-col :span="16" :class="isMobile ? 'Mobilecol' : ''">
							<el-row>
								<el-col :span="12">
									<el-form-item label="最高学历" prop="academic">
										<el-select @change="testPash('academic', form.academic)" v-model="form.academic" placeholder="请选择" filterable>
											<el-option v-for="item in education" :key="item.academic" :label="item.label" :value="item.academic"> </el-option>
										</el-select>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label="毕业学校" prop="school">
										<el-input v-model.trim="form.school" @blur="testPash('school', form.school)"></el-input>
									</el-form-item>
								</el-col>
							</el-row>

							<el-row>
								<el-col :span="12">
									<el-form-item label="毕业日期" prop="graduateTime">
										<el-date-picker @blur="testPash('graduateTime', form.graduateTime)" :picker-options="pickerOptions" v-model="form.graduateTime" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"></el-date-picker>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label="毕业证书编号" v-if="!form.isStudyAbroad">
										<el-input v-model.trim="form.educations.diplomaCode"></el-input>
									</el-form-item>
								</el-col>
							</el-row>

							<el-row>
								<el-col :span="6">
									<el-form-item label="学制" prop="schoolSystem">
										<el-input v-model.trim="form.schoolSystem" type="number" @blur="testPash('schoolSystem', form.schoolSystem)">
											<i slot="suffix" style="font-style:normal;margin-right: 8px;">年</i>
										</el-input>
									</el-form-item>
								</el-col>
								<el-col :span="12" :offset="6">
									<el-form-item label="专业" prop="major">
										<el-input v-model.trim="form.major" @blur="testPash('major', form.major)"></el-input>
									</el-form-item>
								</el-col>
							</el-row>

							<el-row>
								<el-col :span="12">
									<el-form-item label="是否国外学历" prop="isStudyAbroad">
										<el-radio-group v-model="form.isStudyAbroad">
											<el-radio :label="true">是</el-radio>
											<el-radio :label="false">否</el-radio>
										</el-radio-group>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label="是否全日制" v-if="!form.isStudyAbroad">
										<el-radio-group v-model="form.educations.type">
											<el-radio label="全日制">是</el-radio>
											<el-radio label="非全日制">否</el-radio>
										</el-radio-group>
									</el-form-item>
								</el-col>
							</el-row>
						</el-col>

						<el-col :span="7" :class="isMobile ? 'Mobilecol' : ''" :offset="1">
							<el-row>
								<el-col>
									<el-card shadow="never">
										<div
											style="
                      height: 1px;
                      width: 88%;
                      background-color: #e4e4e4;
                      position: absolute;
                      top: 48px;
                    "
										></div>
										<el-form-item style="margin: -16px 0 -12px -12px" class="uploadfic">
											<el-upload class="upload-demo" :action="host + '/file'" :on-preview="handlePreview" :on-remove="handleRemove" :before-upload="beforeAvatarUpload" :before-remove="beforeRemove" :on-success="uploadFile" :headers="importHeaders" :limit="1" accept=".png,.jpeg,.jpg" :on-exceed="handleExceed" :file-list="educationFile" v-model="form.firstDegree">
												<div style="margin-left: 40px; text-align: left">
													<el-button size="small" round :disabled="isUploadFile" @click="setLabel('最高学历证书')" style="color: #0c94ea">点击上传 </el-button>
												</div>
											</el-upload>
											<div style="float: left; text-align: left; margin-left: 16px">
												<div><span style="color: red">*</span> 请上传最高学历证书：</div>
												<div
													style="
                            float: left;
                            margin-top: -12px;
                            font-size: 12px;
                          "
												>
													<span style="color: red">.png,.jpg,.jpeg格式图片 且不超过5mb</span>
												</div>
											</div>
										</el-form-item>
									</el-card>
								</el-col>
							</el-row>

							<div class="hr-10"></div>

							<el-row v-if="!form.isStudyAbroad">
								<el-col>
									<el-card shadow="never">
										<div
											style="
                      height: 1px;
                      width: 88%;
                      background-color: #e4e4e4;
                      position: absolute;
                      top: 48px;
                    "
										></div>
										<el-form-item style="margin: -16px 0 -12px -12px" class="uploadfic">
											<el-upload class="upload-demo" :action="host + '/file'" :on-preview="handlePreview" :on-remove="handleRemove" :before-upload="beforeAvatarUpload" :before-remove="beforeRemove" :on-success="uploadFile" :headers="importHeaders" :limit="1" accept=".png,.jpg,.jpeg" :on-exceed="handleExceed" :file-list="informationRecordFormFile">
												<div style="margin-left: 40px; text-align: left">
													<el-button size="small" round :disabled="isUploadFile" @click="setLabel('最高学历电子信息备案表')" style="color: #0c94ea">点击上传 </el-button>
												</div>
											</el-upload>
											<div style="float: left; text-align: left; margin-left: 16px">
												<div>
													请上传最高学历备案表：
												</div>
												<div>国外学历可不上传电子注册备案表</div>
												<div>教育部学历证书电子注册备案表</div>
												<div
													style="
                            float: left;
                            margin-top: -12px;
                            font-size: 12px;
                          "
												>
													<span style="color: red">.png,.jpg,.jpeg格式图片 且不超过5mb</span>
												</div>
											</div>
										</el-form-item>
									</el-card>
								</el-col>
							</el-row>

							<div class="hr-10"></div>

							<el-row>
								<el-col>
									<el-card shadow="never">
										<div
											style="
                      height: 1px;
                      width: 88%;
                      background-color: #e4e4e4;
                      position: absolute;
                      top: 48px;
                    "
										></div>
										<el-form-item style="margin: -16px 0 -12px -12px" class="uploadfic">
											<el-upload class="upload-demo" :action="host + '/file'" :on-preview="handlePreview" :on-remove="handleRemove" :before-upload="beforeAvatarUpload" :before-remove="beforeRemove" :on-success="uploadFile" :headers="importHeaders" :limit="1" accept=".png,.jpg,.jpeg" :on-exceed="handleExceed" :file-list="academicDegreeFile">
												<div style="margin-left: 40px; text-align: left">
													<el-button size="small" round :disabled="isUploadFile" @click="setLabel('最高学位证书')" style="color: #0c94ea">点击上传 </el-button>
												</div>
											</el-upload>
											<div style="float: left; text-align: left; margin-left: 16px">
												<div><span style="color: red">*</span>学位证书 (复印件，加盖所在单位公章)：</div>
												<div>教育部学位证书</div>
												<div
													style="
                            float: left;
                            margin-top: -12px;
                            font-size: 12px;
                          "
												>
													<span style="color: red">.png,.jpg,.jpeg格式图片 且不超过5mb</span>
												</div>
											</div>
										</el-form-item>
									</el-card>
								</el-col>
							</el-row>
						</el-col>
					</el-row>
					<el-row class="fl">
						<el-col>
							<el-button type="primary" size="small" @click="getAddEducation" style="color: #FFFFFF" :disabled="overstepEducation">
								<i class="el-icon-plus"></i>
								添加学历
							</el-button>
						</el-col>
					</el-row>
					<div style="clear: both"></div>
				</el-card>

				<div class="hr-20" v-show="isMobile ? tabName == 'academic' : true"></div>

				<!-- 添加的学历 -->
				<el-card v-for="(item, index) in form.talentSecondEducation" :key="item.number" style="margin-bottom: 20px">
					<el-form label-position="top" ref="form4" :model="form" :rules="rules" label-width="140px">
						<div style="text-align: left">
							<el-button type="danger" size="small" @click="getDeleteEducation(index, item)" style="color: #FFFFFF">
								<i class="el-icon-delete"></i>
								去除学历
							</el-button>
						</div>
						<el-row>
							<el-col :span="16" :class="isMobile ? 'Mobilecol' : ''">
								<el-row>
									<el-col :span="12">
										<el-form-item label="学历">
											<el-select v-model="item.academic" placeholder="请选择" filterable clearable>
												<el-option v-for="itemAcademic in education" :key="itemAcademic.academic" :label="itemAcademic.label" :value="itemAcademic.academic"> </el-option>
											</el-select>
										</el-form-item>
									</el-col>
									<el-col :span="12">
										<el-form-item label="毕业学校" prop="school">
											<el-input v-model.trim="item.school"></el-input>
										</el-form-item>
									</el-col>
								</el-row>

								<el-row>
									<el-col :span="12">
										<el-form-item label="毕业日期">
											<el-date-picker :picker-options="pickerOptions" v-model="item.graduateTime" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"></el-date-picker>
										</el-form-item>
									</el-col>
									<el-col :span="12">
										<el-form-item label="毕业证书编号" v-if="!item.isStudyAbroad">
											<el-input v-model.trim="item.diplomaCode"></el-input>
										</el-form-item>
									</el-col>
								</el-row>

								<el-row>
									<el-col :span="6">
										<el-form-item label="学制">
											<el-input v-model.trim="item.schoolSystem" type="number"></el-input>
											<i slot="suffix" style="font-style:normal;margin-right: 8px;">年</i>
										</el-form-item>
									</el-col>

									<el-col :span="12" :offset="6">
										<el-form-item label="专业" prop="major">
											<el-input v-model.trim="item.major"></el-input>
										</el-form-item>
									</el-col>
								</el-row>

								<el-row>
									<el-col :span="12">
										<el-form-item label="是否国外学历">
											<el-radio-group @change="studyAbroad22" v-model="item.isStudyAbroad">
												<el-radio :label="true">是</el-radio>
												<el-radio :label="false">否</el-radio>
											</el-radio-group>
										</el-form-item>
									</el-col>
									<el-col :span="12">
										<el-form-item label="是否全日制" v-if="!item.isStudyAbroad">
											<el-radio-group v-model="item.type">
												<el-radio label="全日制">是</el-radio>
												<el-radio label="非全日制">否</el-radio>
											</el-radio-group>
										</el-form-item>
									</el-col>
								</el-row>
							</el-col>

							<el-col :span="7" :class="isMobile ? 'Mobilecol' : ''" :offset="1">
								<el-row>
									<el-col>
										<el-card shadow="never">
											<div style="height: 1px;width: 88%;background-color: #e4e4e4;position: absolute;top: 48px"></div>
											<el-form-item style="margin: -16px 0 -12px -12px" class="uploadfic">
												<el-upload class="upload-demo" :action="host + '/file'" :on-preview="handlePreview" :on-remove="handleRemove" :before-upload="beforeAvatarUpload" :before-remove="beforeRemove" :on-success="uploadFile" :headers="importHeaders" :limit="1" accept=".png,.jpg,.jpeg" :on-exceed="handleExceed" :file-list="item.xlzs" v-model="item.xlzs">
													<div style="margin-left: 40px; text-align: left">
														<el-button size="small" round :disabled="isUploadFile" @click="setLabel('学历证书', item.id)" style="color: #0c94ea">点击上传 </el-button>
													</div>
												</el-upload>
												<div style="float: left; text-align: left; margin-left: 16px">
													<div>学历证书 (复印件，加盖所在单位公章)：</div>
													<div>教育部学历证书</div>
													<div
														style="
                                        float: left;
                                        margin-top: -12px;
                                        font-size: 12px;
                                      "
													>
														<span style="color: red">.png,.jpg,.jpeg格式图片 且不超过5mb</span>
													</div>
												</div>
											</el-form-item>
										</el-card>
									</el-col>
								</el-row>

								<div class="hr-10"></div>

								<el-row v-if="!item.isStudyAbroad">
									<el-col>
										<el-card shadow="never">
											<div
												style="
                                  height: 1px;
                                  width: 88%;
                                  background-color: #e4e4e4;
                                  position: absolute;
                                  top: 48px;
                                "
											></div>
											<el-form-item style="margin: -16px 0 -12px -12px" class="uploadfic">
												<el-upload class="upload-demo" :action="host + '/file'" :on-preview="handlePreview" :on-remove="handleRemove" :before-upload="beforeAvatarUpload" :before-remove="beforeRemove" :on-success="uploadFile" :headers="importHeaders" :limit="1" accept=".png,.jpg,.jpeg" :on-exceed="handleExceed" :file-list="item.xldzxxbab">
													<div style="margin-left: 40px; text-align: left">
														<el-button size="small" round :disabled="isUploadFile" @click="setLabel('学历电子信息备案表', item.id)" style="color: #0c94ea">点击上传 </el-button>
													</div>
												</el-upload>
												<div style="float: left; text-align: left; margin-left: 16px">
													<div>国外学历可不上传电子注册备案表</div>
													<div>教育部学历电子注册备案表</div>
													<div
														style="
                                        float: left;
                                        margin-top: -12px;
                                        font-size: 12px;
                                      "
													>
														<span style="color: red">.png,.jpg,.jpeg格式图片 且不超过5mb</span>
													</div>
												</div>
											</el-form-item>
										</el-card>
									</el-col>
								</el-row>

								<div class="hr-10"></div>

								<el-row>
									<el-col>
										<el-card shadow="never">
											<div style="height: 1px;width: 88%;background-color: #e4e4e4;position: absolute;top: 48px;"></div>
											<el-form-item style="margin: -16px 0 -12px -12px">
												<el-upload class="upload-demo" :action="host + '/file'" :on-preview="handlePreview" :on-remove="handleRemove" :before-upload="beforeAvatarUpload" :before-remove="beforeRemove" :on-success="uploadFile" :headers="importHeaders" :limit="1" accept=".png,.jpg,.jpeg" :on-exceed="handleExceed" :file-list="item.xwzs">
													<div style="margin-left: 40px; text-align: left" class="uploadfic">
														<el-button size="small" round :disabled="isUploadFile" @click="setLabel('学位证书', item.id)" style="color: #0c94ea">点击上传 </el-button>
													</div>
												</el-upload>
												<div style="float: left; text-align: left; margin-left: 16px">
													<div>学位证书(复印件，加盖所在单位公章)：</div>
													<div style="float: left;margin-top: -12px;font-size: 12px;">
														<span style="color: red">.png,.jpg,.jpeg格式图片 且不超过5mb</span>
													</div>
												</div>
											</el-form-item>
										</el-card>
									</el-col>
								</el-row>
							</el-col>
						</el-row>
					</el-form>
				</el-card>
				<el-card v-show="isMobile ? tabName == 'enterprise' : true">
					<el-row>
						<el-col :span="16" :class="isMobile ? 'Mobilecol' : ''">
							<el-row>
								<el-col :span="12">
									<el-form-item label="目前供职单位" prop="enterpriseName">
										<el-select @blur="testPash('enterpriseName', form.enterpriseName)" v-model.trim="form.enterpriseName" filterable placeholder="请选择(可搜索)" @change="currentSel">
											<el-option v-for="item in Qiyes" :key="item.enterpriseId" :label="item.label" :value="item.enterpriseId"> </el-option>
										</el-select>
										<div style="font-size: 12px">
											若找不到自己所在公司，应是您所在的供职单位尚未注册
										</div>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label="所在单位职务" prop="position">
										<el-input v-model.trim="form.position" @blur="testPash('position', form.position)"></el-input>
									</el-form-item>
								</el-col>
							</el-row>

							<el-row>
								<el-col :span="12">
									<el-form-item label="职称类别" prop="value">
										<el-select v-model="form.value" filterable placeholder="请选择" clearable @change="requestItem">
											<el-option v-for="item in optionsCertificate" :key="item.value" :label="item.label" :value="item.value"> </el-option>
										</el-select>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label="入职时间" prop="hireDate">
										<el-date-picker @blur="testPash('hireDate', form.hireDate)" :picker-options="pickerOptions" v-model="form.hireDate" type="date" placeholder="劳动合同上的时间" value-format="yyyy-MM-dd"> </el-date-picker>
									</el-form-item>
								</el-col>
							</el-row>
							<el-row>
								<el-col :span="12">
									<el-form-item label="是否省外引进" prop="rankLevel">
										<el-select @blur="testPash('rankLevel', form.rankLevel)" v-model.trim="form.rankLevel" filterable placeholder="请选择">
											<el-option v-for="items in introduce" :key="items.number" :value="items.value"> </el-option>
										</el-select>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label="从事专业领域" prop="skillCategory">
										<el-select @blur="testPash('skillCategory', form.skillCategory)" v-model.trim="form.skillCategory" filterable placeholder="请选择">
											<el-option v-for="items in professionalField" :key="items.value" :value="items.value"> </el-option>
										</el-select>
									</el-form-item>
								</el-col>
							</el-row>
						</el-col>

						<el-col :span="7" :class="isMobile ? 'Mobilecol' : ''" :offset="1">
							<el-row>
								<el-col>
									<el-card shadow="never">
										<div
											style="
                      height: 1px;
                      width: 88%;
                      background-color: #e4e4e4;
                      position: absolute;
                      top: 48px;
                    "
										></div>
										<el-form-item style="margin: -16px 0 -12px -12px" class="uploadfic">
											<el-upload class="upload-demo" :action="host + '/file'" :on-preview="handlePreview" :on-remove="handleRemove" :before-upload="beforeFileUpload" :before-remove="beforeRemove" :on-success="uploadFile" :headers="importHeaders" :limit="1" accept=".pdf" :on-exceed="handleExceed" :file-list="LaborContractFile">
												<div style="margin-left: 40px; text-align: left">
													<el-button size="small" round :disabled="isUploadFile" @click="setLabel('劳动合同')" style="color: #0c94ea">点击上传 </el-button>
												</div>
											</el-upload>
											<div style="float: left; text-align: left; margin-left: 16px">
												<div><span style="color: red">* </span>请上传与单位依法签订的劳动合同：</div>
												<div>
													劳动合同(上传的内容须包含首页、甲乙方信息、岗位、签订时间和盖章页)
												</div>
												<div
													style="
                            float: left;
                            margin-top: -12px;
                            font-size: 12px;
                          "
												>
													<span style="color: red">只能上传pdf文件且不超过7mb</span>
												</div>
											</div>
										</el-form-item>
									</el-card>
								</el-col>
							</el-row>

							<div class="hr-10"></div>

							<el-row>
								<el-col>
									<el-card shadow="never">
										<div
											style="
                      height: 1px;
                      width: 88%;
                      background-color: #e4e4e4;
                      position: absolute;
                      top: 48px;
                    "
										></div>
										<el-form-item style="margin: -16px 0 -12px -12px" class="uploadfic">
											<el-upload class="upload-demo" :action="host + '/file'" :on-preview="handlePreview" :on-remove="handleRemove" :before-upload="beforeFileUpload" :before-remove="beforeRemove" :on-success="uploadFile" :headers="importHeaders" :limit="1" accept=".pdf" :on-exceed="handleExceed" :file-list="incomeTaxFile">
												<div style="margin-left: 40px; text-align: left">
													<el-button size="small" round :disabled="isUploadFile" @click="setLabel('个人所得税')" style="color: #0c94ea">点击上传 </el-button>
												</div>
											</el-upload>
											<div style="float: left; text-align: left; margin-left: 16px">
												<div>
													个人所得税（上传最近12个月的个税证明）:
												</div>
												<div
													style="
                            float: left;
                            margin-top: -12px;
                            font-size: 12px;
                          "
												>
													<span style="color: red">只能上传pdf文件 且不超过7mb</span>
												</div>
											</div>
										</el-form-item>
									</el-card>
								</el-col>
							</el-row>
						</el-col>
					</el-row>

					<!-- 所获荣誉 -->
					<div style="text-align: left;margin-bottom: 10px">
						所获荣誉
						<el-button type="primary" size="small" style="color: #FFFFFF;margin-left: 8px" @click="getAddHonor">
							<i class="el-icon-plus"></i>
							添加荣誉
						</el-button>
					</div>

					<el-card shadow="never" v-for="(item, index) in form.honors" :key="item.number" style="margin-top: 20px">
						<div class="fl">
							<el-button type="danger" size="small" style="color: #FFFFFF" @click="getDeleteHonor(index, item)">
								<i class="el-icon-delete"></i>
								删除
							</el-button>
						</div>
						<div style="clear: both"></div>
						<el-row>
							<el-col :span="16" :class="isMobile ? 'Mobilecol' : ''">
								<el-row>
									<el-col :span="10">
										<el-form-item label="所获荣誉名称">
											<el-input v-model.trim="item.honorName" placeholder="所获荣誉名称"> </el-input>
										</el-form-item>
									</el-col>

									<el-col :span="10" :offset="4">
										<el-form-item label="实施部门">
											<el-input v-model.trim="item.implementationDepartment" placeholder="实施部门"> </el-input>
										</el-form-item>
									</el-col>
								</el-row>

								<el-row>
									<el-col :span="10">
										<el-form-item label="获奖年月">
											<el-date-picker :picker-options="pickerOptions" v-model="item.awardYear" type="date" placeholder="获奖年月" value-format="yyyy-MM-dd"> </el-date-picker>
										</el-form-item>
									</el-col>

									<el-col :span="10" :offset="4">
										<el-form-item label="所获奖励级别">
											<el-select v-model.trim="item.rewardLevel" filterable placeholder="请选择">
												<el-option v-for="items in awardLevel" :key="items.number" :value="items.value"> </el-option>
											</el-select>
										</el-form-item>
									</el-col>
								</el-row>
							</el-col>

							<el-col :span="7" :class="isMobile ? 'Mobilecol' : ''" :offset="1">
								<el-col>
									<el-card shadow="never" style="position: relative">
										<div style="height: 1px;width: 88%;background-color: #e4e4e4;position: absolute;top: 48px;"></div>
										<el-form-item style="margin: -16px 0 -12px -12px" class="uploadfic">
											<el-upload class="upload-demo" :action="host + '/file'" :on-preview="handlePreview" :on-remove="handleRemove" :before-upload="beforeAvatarUpload" :before-remove="beforeRemove" :on-success="uploadFile" :headers="importHeaders" :limit="20" accept=".png,.jpg,.jpeg" :on-exceed="handleExceed" :file-list="item.shry">
												<div style="margin-left: 40px; text-align: left">
													<el-button size="small" round :disabled="isUploadFile" @click="setLabel('荣誉证书', item.id)" style="color: #0c94ea">点击上传 </el-button>
												</div>
											</el-upload>
											<div style="float: left; text-align: left; margin-left: 16px">
												<div>所获得各类荣誉的证书(非必须)：</div>
												<div style="float: left;margin-top: -12px;font-size: 12px;">
													<span style="color: red">.png,.jpg,.jpeg格式图片 且不超过5mb</span>
												</div>
											</div>
										</el-form-item>
									</el-card>
								</el-col>
							</el-col>
						</el-row>
					</el-card>

					<!-- 工作履历 -->
					<div style="text-align: left;margin: 10px 0">
						工作履历
						<el-button type="primary" size="small" style="color: #FFFFFF;margin-left: 8px" @click="getAddWorkingExperience">
							<i class="el-icon-plus"></i>
							添加工作履历
						</el-button>
					</div>

					<el-card shadow="never" v-for="(item, index) in form.resumes" :key="item.number" style="margin-top: 20px">
						<div class="fl">
							<el-button type="danger" size="small" style="color: #FFFFFF" @click="getDeleteWorkingExperience(index, item)">
								<i class="el-icon-delete"></i>
								删除
							</el-button>
						</div>
						<div style="clear: both"></div>
						<el-row>
							<el-col :span="7" :class="isMobile ? 'Mobilecol' : ''">
								<el-form-item label="工作单位名称">
									<el-input v-model.trim="item.companyName" placeholder="工作单位名称"> </el-input>
								</el-form-item>
							</el-col>
							<el-col :span="7" :class="isMobile ? 'Mobilecol' : ''" :offset="1">
								<el-form-item label="起止年月">
									<el-date-picker v-model="item.startDate" type="date" placeholder="劳动合同上的时间" value-format="yyyy-MM-dd"> </el-date-picker>
									<el-date-picker v-model="item.endDate" type="date" placeholder="劳动合同上的时间" value-format="yyyy-MM-dd"> </el-date-picker>
								</el-form-item>
							</el-col>
							<el-col :span="7" :class="isMobile ? 'Mobilecol' : ''" :offset="1">
								<el-form-item label="职务">
									<el-input v-model.trim="item.jobTitle" placeholder="职务"> </el-input>
								</el-form-item>
							</el-col>
						</el-row>
					</el-card>

					<el-row>
						<el-col>
							<el-col :span="24">
								<el-form-item label="个人简历(500字内)">
									<el-input autosize maxlength="500" type="textarea" :row="5" v-model="form.resume" placeholder="个人简历字，数不得超过500字"> </el-input>
								</el-form-item>
							</el-col>
						</el-col>
					</el-row>
				</el-card>
			</el-form>
		</div>

		<el-row style="margin: 24px 0 0" v-show="isMobile ? tabName == 'enterprise' : true">
			<el-col :span="6" :offset="6">
				<el-button type="info" @click="onPreview">预览提交</el-button>
				<el-button type="primary" @click="temporarySubmit" v-if="form.status == 0">暂存</el-button>
			</el-col>
			<el-col :span="6">
				<el-button type="primary" @click="onSubmit('form')">提交审核</el-button>
			</el-col>
		</el-row>

		<el-dialog title="查看" :visible.sync="pisDialog" :width="oWidth" center>
			<div class="pisDialog">
				<img alt="" :src="pispath" v-if="pisDialog" style="width: 100%" />
				<iframe :src="pdf" frameborder="0" style="width: 100%; height: 700px" v-if="pdf"></iframe>
			</div>

			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="pisDialog = false">确 定</el-button>
			</span>
		</el-dialog>

		<!--江西省社会保险个人参保缴费证明模版-->
		<el-dialog title="江西省社会保险个人参保缴费证明模版" :visible.sync="template" width="720px" class="template">
			<img src="@/assets/Template.png" alt style="margin-top: -40px; margin-bottom: -56px; width: 100%" />
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" @click="template = false">关闭</el-button>
			</span>
		</el-dialog>

		<!--人才目录等级说明-->
		<el-dialog :visible.sync="catalog" width="900px" class="template">
			<div v-html="illustrate" style="text-align: left;"></div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" @click="catalog = false">关闭</el-button>
			</span>
		</el-dialog>

		<!-- 预览提交 -->
		<el-dialog title="预览您的认证信息" :visible.sync="DialogPreview" width="80%">
			<preview :item="itemAuthentication" v-if="DialogPreview"></preview>
			<span slot="footer" class="dialog-footer">
				<el-button @click="closePersonalInformation" type="primary" size="small">关 闭</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import configDate from "../../../utils/config";
import china from "@/utils/china_regions";
import Preview from "@/components/personal/preview";
import url from "../../../utils/config";

export default {
	data() {
		return {
			isMobile: false,
			tabName: "user1",
			valid: true,
			fullscreenLoading: false,
			isAddress: true,
			template: false, //江西省社会保险个人参保缴费证明模版弹窗
			catalog: false, // 人才目录等级说明弹窗
			itemAuthentication: {}, //传给子组件的信息
			DialogPreview: false, //预览提交的弹窗
			overstepEducation: false, // 超出数量禁止添加学历
			rulesMessages: "", // 未填写地址时的警告
			awardLevel: configDate.awardLevel, //所获荣誉级别
			professionalField: configDate.professionalField, // 从事专业领域
			optionsCertificate: configDate.optionsCertificate, //职称类别
			illustrate: configDate.illustrate, //人才说明
			pdf: "",
			oWidth: "600px",
			/*
            引进地方
            */
			introduce: [
				{ value: "省内", number: "introduce1" },
				{ value: "省外", number: "introduce2" },
				{ value: "国外", number: "introduce3" },
			],
			item: {},
			/*
            选择日期时不可以选择未来日期
            */
			pickerOptions: {
				disabledDate: (time) => {
					return this.dealDisabledDate(time);
				},
			},
			importHeaders: { token: sessionStorage.getItem("Token") },
			isUploadFile: false, // 文件时其他文件按钮禁用
			IsCode: false, //当身份证被占用时身份证可编辑状态
			pispath: "", // 查看文件时的路径
			pisDialog: false, // 查看文件弹窗
			fileLabel: "", //文件的备注名
			china: china,
			informationStatus: 0,
			host: configDate.url,
			bareheadedWorkPhotoFile: [], //免冠工作照
			IDFile: [], //身份证
			educationFile: [], //最高学历证书
			informationRecordFormFile: [], //最高学历电子信息备案表
			academicDegreeFile: [], //最高学位证
			LaborContractFile: [], //劳动合同
			incomeTaxFile: [], // 个人所得税
			typesTalentsFile: [], //人才类型附件
			socialSecurityFile: [], //社保附件
			opBack: configDate.GanZhou, //选择城中心的房
			block: "", //城中心的房
			solSecFile: false, //验证社保附件
			LaContFile: false, //验证劳动合同附件
			eduatFile: false, //验证最高学历附件
			academictFile: false, //验证最高学位附件
			IDcardFile: false,
			isUpdate: false, // 判断是否为创建信息
			temporary: [], //暂存
			form: {
				id: 0,
				name: "", //名字
				sex: 0, //性别
				value: "",
				hasHouse: false, //是否在城区有房
				buyHouse: false,
				hasSocialSecurity: false,
				birthday: "", //生日
				nation: "", //民族
				identityType: "", //证件类型
				identityCode: "", //证件号码
				startEffectiveDate: "", // 有效证件开始日期
				endEffectiveDate: "", // 有效证件结束日期
				citizenship: "中国", //国籍
				birthplace: "", //籍贯
				school: "", //毕业学校
				major: "", //专业
				schoolSystem: "", // 学制
				academic: "", //学历
				graduateTime: "", //毕业日期
				diplomaCode: "", //毕业证书编号
				isStudyAbroad: false,
				degree: "",
				dayType: "全日制", // 是否全日制
				ownerId: sessionStorage.getItem("ID"),
				firstDegree: "", //  学历证书
				firstFilingForm: "", //  备案表
				resume: "", //  个人简历
				/*
                最高学历
                */
				educations: {
					academic: "", //  最高学历
					school: "", //毕业学校
					major: "", //专业
					graduateTime: "", //毕业日期
					diplomaCode: "", //毕业证书编号
					isStudyAbroad: false, // 是否国外学历
					type: "全日制", // 是否是全日制
					schoolSystem: 0, // 学制
					sort: 0, // 排序
					ownerId: sessionStorage.getItem("ID"),
				},
				/*
                更多的学历
                */
				talentSecondEducation: [],
				block: "", //房区
				type: "", //人才类别
				abroadAcademic: "", //国外学历
				rankCategory: "", //职称类别
				team: "", //所属人才队伍
				/*
                所获荣誉
                */
				honors: [],
				/*
                工作履历
                */
				resumes: [],
				enterpriseName: "", //目前供职公司
				enterpriseId: 0, //目前供职公司Id
				rankLevel: "", // 是否省外引进
				skillCategory: "", // 从事专业领域
				enterpriseUserName: "",
				isEnterprise: false,

				position: "", //所在公司职务
				hireDate: "", //入职时间
				socialSecurityPaymentTime: "",
				lastEnterprise: "", //上一个工作单位
				lastHireDate: "", //上一个工作单位入职时间
				occupationId: 0, // 职业ID
				phone: "", //手机
				email: "", //邮箱
				fileIds: [],
				allFile: [],
				details: "",
				addresses: [], //地址
				caseAddress: [],
				addressDetails: "",
				addressDeposit: "",
				address: "",
				addressId: 0,
				status: 0, //状态
				file: [],
			},
			educationsFileId: 0,
			identity: [
				{ identityType: "居民身份证", label: "居民身份证" },
				{ identityType: "护照", label: "护照" },
			], //证件类型
			identityType: "居民身份证", //证件类型
			nation: configDate.nation, // 选择民族
			typesTalents: configDate.typesTalents, // 选择人才类型
			nationality: configDate.country, // 选择国籍
			education: configDate.education, // 选择学历
			Qiyes: [], //目前供职单位
			enterpriseId: "", //企业id
			details: "", //  详细地址
			detailsRules: [(v) => !!v || "请输入详细地址"],
			rules: {
				name: [
					{ required: true, message: "请输入姓名", trigger: "blur" },
					{
						min: 2,
						max: 10,
						message: "长度在 2 到 10 个字符",
						trigger: "blur",
					},
					{
						required: true,
						pattern: /^[\u4e00-\u9fa5_a-zA-Z0-9.·-]+$/,
						message: "姓名不支持特殊字符",
						trigger: "blur",
					},
				],
				labourServices: [
					{
						required: true,
						message: "请上传与单位依法签订的劳动合同",
						trigger: "change",
					},
				],
				socialSecurityAnnex: [{ required: true, message: "请上传缴纳社保附件", trigger: "change" }],
				hasSocialSecurity: [
					{
						required: true,
						message: "请选择是否在赣州经开区缴纳社保",
						trigger: "change",
					},
				],
				firstDegree: [{ required: true, message: "请上传最高学历证书", trigger: "change" }],
				firstFilingForm: [
					{
						required: true,
						message: "请上传最高学历备案表",
						trigger: "change",
					},
				],
				isStudyAbroad: [{ required: true, message: "请选择是否国外学历", trigger: "change" }],
				birthday: [{ required: true, message: "请输入生日", trigger: "blur" }],
				startEffectiveDate: [
					{
						required: true,
						message: "请输入证件有效开始日期",
						trigger: "blur",
					},
				],
				endEffectiveDate: [
					{
						required: true,
						message: "请输入证件有效结束日期",
						trigger: "blur",
					},
				],
				nation: [{ required: true, message: "请输入民族", trigger: "change" }],
				socialSecurityPaymentTime: [{ required: true, message: "请输入缴纳社保时间", trigger: "change" }],
				identityType: [{ required: true, message: "请输入证件类型", trigger: "blur" }],
				identityCode: [{ required: true, message: "请输入身份证号", trigger: "blur" }, (v) => !!v || "请输入身份证", (v) => /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|[a-zA-Z\d])$)/.test(v) || "请输入正确的身份证"],
				citizenship: [{ required: true, message: "请输入国籍", trigger: "change" }],
				birthplace: [
					{
						required: true,
						message: "请输入籍贯 格式为(省名+县名，如：江西安远)",
						trigger: "blur",
					},
				],
				school: [{ required: true, message: "请输入毕业学校", trigger: "blur" }],
				major: [{ required: true, message: "请输入专业", trigger: "blur" }],
				schoolSystem: [{ required: true, message: "请输入学制", trigger: "blur" }],
				academic: [{ required: true, message: "请输入最高学历", trigger: "change" }],
				graduateTime: [{ required: true, message: "请输入毕业时间", trigger: "blur" }],
				diplomaCode: [{ required: true, message: "请输入毕业证书编号", trigger: "blur" }],
				type: [{ required: true, message: "请输入人才类型", trigger: "blur" }],
				value: [{ required: true, message: "请输入职称类别", trigger: "change" }],
				team: [{ required: true, message: "请输入人才队伍", trigger: "blur" }],
				enterpriseName: [{ required: true, message: "请输入目前供职公司", trigger: "change" }],
				position: [{ required: true, message: "请输入公司职位", trigger: "blur" }],
				hireDate: [{ required: true, message: "请输入入职时间", trigger: "blur" }],
				rankLevel: [{ required: true, message: "请选择是否省外引进", trigger: "change" }],
				skillCategory: [{ required: true, message: "请选择从事专业领域", trigger: "change" }],
				phone: [
					{ required: true, message: "请输入手机号", trigger: "blur" },
					{
						pattern: /^1[345789]\d{9}$/,
						message: "请输入正确的手机号",
						trigger: "blur",
					},
				],
				email: [
					{ required: true, message: "请输入邮箱地址", trigger: "blur" },
					{ type: "email", message: "请输入正确的邮箱地址", trigger: "blur" },
				],
				addresses: [{ required: true, message: "请输入户籍地址", trigger: "change" }],
				details: [{ required: true, message: "请输入详情地址", trigger: "blur" }],
			},
		};
	},
	created() {
		this.form.id = sessionStorage.getItem("ID");
		this.leix = sessionStorage.getItem("Leix");

		this.getTalentInformation(); //查找人才详细信息

		this.getEnterpriseInformation(); //获取公司信息
	},
	mounted() {
		if (this.commonFunction._isMobile()) {
			this.isMobile = true;
		} else {
			this.isMobile = false;
		}
	},
	methods: {
		//查找人才详细信息
		getTalentInformation(id, formName) {
			let self = this;
			this.$axios
				.get("/talent/detail?id=" + self.form.id)
				.then(function(res) {
					if (res.data.status === 200) {
						self.getTemporary(res.data.data.status, res.data.data.id); //暂存回显

						console.log("个人信息", res.data.data);

						self.isAddress = res.data.data.address === null;
						let information = self.form;
						let response = res.data.data;
						information.honors = response.honors; // 荣誉
						information.resumes = response.resumes; // 工作履历
						response.educations.forEach((item) => {
							if (item.sort === 0) {
								self.form.educations = item;
								information.sort = item.sort;
								information.academic = item.academic;
								information.school = item.school;
								information.graduateTime = item.graduateTime;
								information.schoolSystem = item.schoolSystem;
								information.major = item.major;
								information.isStudyAbroad = item.isStudyAbroad;
							}
						});
						/*
                    学历
                     */
						information.talentSecondEducation = response.educations;
						information.talentSecondEducation.forEach((item, index) => {
							if (item.sort === 0) {
								information.talentSecondEducation.splice(index, 1);
							}
						});

						information.honors.forEach((item) => {
							if (!!item.files) {
								item.shry = [];
								item.files.forEach((fileItem) => {
									item.shry.push({
										id: fileItem.id,
										name: fileItem.name,
										url: configDate.fileurl + fileItem.path,
									});
								});
							}
						});
						for (let i = 0; i < information.talentSecondEducation.length; i++) {
							const item = information.talentSecondEducation[i];
							if (item.sort === 0) {
								continue;
							}
							item.xlzs = [];
							item.xldzxxbab = [];
							item.xwzs = [];
							item.files.forEach((fileItem) => {
								switch (fileItem.memo) {
									case "学历证书":
										item.xlzs.push({
											id: fileItem.id,
											name: fileItem.name,
											url: configDate.fileurl + fileItem.path,
										});
										break;
									case "学历电子信息备案表":
										item.xldzxxbab.push({
											id: fileItem.id,
											name: fileItem.name,
											url: configDate.fileurl + fileItem.path,
										});
										break;
									case "学位证书":
										item.xwzs.push({
											id: fileItem.id,
											name: fileItem.name,
											url: configDate.fileurl + fileItem.path,
										});
										break;
								}
							});
						}
						information.enterpriseUserName = response.enterpriseName;
						information.birthday = response.birthday;
						information.birthplace = response.birthplace;
						information.block = self.block;
						information.buyHouse = response.buyHouse;
						information.citizenship = response.citizenship;
						information.email = response.email;
						information.endEffectiveDate = response.endEffectiveDate;
						information.hasHouse = response.hasHouse;
						information.hasSocialSecurity = response.hasSocialSecurity;
						information.id = response.id;
						information.identityCode = response.identityCode;
						information.identityType = response.identityType;
						information.name = response.name;
						information.nation = response.nation;
						information.sex = response.sex;
						information.status = response.status;
						information.socialSecurityPaymentTime = response.socialSecurityPaymentTime;
						information.startEffectiveDate = response.startEffectiveDate;
						information.resume = response.resume;
						information.type = response.type;
						information.caseAddress = response.addresses;
						response.addresses.forEach((item, index) => {
							if (index === 0) {
								information.address = response.citizenship + " / " + item.province + " / " + item.city + " / " + item.county;
								information.addressDetails = response.citizenship + item.province + item.city + item.county;
								information.addressId = item.id;
								information.details = item.details;
							}
						});
						self.block = response.block;
						information.enterpriseName = response.enterpriseName;
						information.enterpriseId = response.enterpriseId;
						information.value = response.talentOccupation.rankCategory;
						information.hireDate = response.talentOccupation.hireDate;
						information.rankLevel = response.talentOccupation.rankLevel;
						information.skillCategory = response.talentOccupation.skillCategory;
						information.occupationId = response.talentOccupation.id;

						self.bareheadedWorkPhotoFile = []; //头像
						self.typesTalentsFile = []; //人才类型
						self.socialSecurityFile = []; //社保附件
						self.IDFile = []; //身份证
						self.incomeTaxFile = []; // 个人所得税

						self.educationFile = []; //最高学历证书
						self.informationRecordFormFile = []; //最高学历报告
						self.academicDegreeFile = []; //最高学位证

						self.LaborContractFile = []; //劳动合同
						information.fileIds = [];
						information.allFile = [];

						response.files.forEach((arr) => {
							information.fileIds.push(arr.id);
							information.allFile.push(arr);
							if (arr.memo) {
								switch (arr.memo) {
									case "免冠工作照":
										self.bareheadedWorkPhotoFile.push({
											id: arr.id,
											name: arr.name,
											url: configDate.fileurl + arr.path,
										});
										break;
									case "身份证":
										self.IDFile.push({
											id: arr.id,
											name: arr.name,
											url: configDate.fileurl + arr.path,
										});
										break;
									case "最高学历证书":
										self.educationFile.push({
											id: arr.id,
											name: arr.name,
											url: configDate.fileurl + arr.path,
										});
										break;
									case "最高学历电子信息备案表":
										self.informationRecordFormFile.push({
											id: arr.id,
											name: arr.name,
											url: configDate.fileurl + arr.path,
										});
										break;
									case "最高学位证书":
										self.academicDegreeFile.push({
											id: arr.id,
											name: arr.name,
											url: configDate.fileurl + arr.path,
										});
										break;
									case "人才类型附件":
										self.typesTalentsFile.push({
											id: arr.id,
											name: arr.name,
											url: configDate.fileurl + arr.path,
										});
										break;
									case "社保附件":
										self.socialSecurityFile.push({
											id: arr.id,
											name: arr.name,
											url: configDate.pdffileurl + arr.path,
										});
										break;
									case "个人所得税":
										self.incomeTaxFile.push({
											id: arr.id,
											name: arr.name,
											url: configDate.pdffileurl + arr.path,
										});
										break;
									case "劳动合同":
										self.LaborContractFile.push({
											id: arr.id,
											name: arr.name,
											url: configDate.pdffileurl + arr.path,
										});
										break;
								}
							}
						});

						information.position = response.talentOccupation.position;
						if (self.isUpdate) {
							self.updateInformationSubmit(formName);
							self.isUpdate = false;
						}
					} else if (res.data.data === "Access denide") {
						self.$router.push("/login?name=second");
					} else {
					}
				})
				.catch(function(error) {
					console.error(error);
				});
		},
		goHome() {
			this.$router.go(-1);
		},
		//  获取职称类别是否有无
		requestItem(value) {
			this.item = this.optionsCertificate.find((item) => {
				return item.value === value;
			});
		},

		// 跳转至首页
		request() {
			let newPage = this.$router.resolve({
				name: "EntryStoma",
			});
			window.open(newPage.href, "_blank");
		},

		// 控制时间不可以选择未来时间
		dealDisabledDate(time) {
			let times = Date.now();
			return time.getTime() > times;
		},

		// 给文件加上备注（memo）
		setLabel(lebalname, item) {
			this.isUploadFile = true;
			this.fileLabel = lebalname;
			this.educationsFileId = item;
			setTimeout(() => {
				this.isUploadFile = false;
			}, 6000);
		},
		//附件验证
		AppendixVerify() {
			console.log(this.LaContFile);
			console.log(this.solSecFile);
			console.log(this.eduatFile);
			console.log(this.academictFile);
			// console.log(this.solSecFile)
			if (this.LaborContractFile.length < 1) {
				console.log(2);
				if (!this.LaContFile) {
					this.$message.error("请上传劳动合同");
					console.log(this.LaborContractFile.length, "请上传劳动合同");
					return false;
				}
			} else if (this.socialSecurityFile.length < 1) {
				console.log(3);
				if (!this.solSecFile) {
					this.$message.error("请上传社保附件");
					console.log(this.socialSecurityFile.length, "请上传社保附件");
					return false;
				}
			} else if (this.educationFile.length < 1) {
				console.log(4);
				if (!this.eduatFile) {
					this.$message.error("请上传最高学历证书");
					console.log(this.educationFile.length, "请上传最高学历证书");
					return false;
				}
			} else if (this.academicDegreeFile.length < 1) {
				console.log(5);
				if (!this.academictFile) {
					this.$message.error("请上传最高学位证书");
					console.log(this.academicDegreeFile.length, "请上传最高学位证书");
					return false;
				}
			} else if (this.IDFile.length < 1) {
				console.log(6);
				if (!this.IDcardFile) {
					this.$message.error("请上传身份证 正反面至少两张");
					console.log(this.IDFile.length, "请上传身份证 正反面至少两张");
					return false;
				}
			}
			return true;
		},

		// 提交人才认证信息
		onSubmit(formName) {
			let self = this;
			if (self.form.status === 11) {
				self.$confirm("您的资料正在认证中，提交后需要2~3个工作日重新审核，确定要重新提交吗？")
					.then((_) => {
						self.updateInformationSubmit(formName);
					})
					.catch((_) => {});
			} else if (self.form.status === 21) {
				self.$confirm("您的身份已认证，提交后需要2~3个工作日重新审核，确定要重新提交吗？？")
					.then((_) => {
						self.updateInformationSubmit(formName);
					})
					.catch((_) => {});
			} else if (self.form.status === 0) {
				self.firstInformationSubmit(formName);
			} else {
				self.updateInformationSubmit(formName);
			}
		},

		// 判断户籍地址是否已填写
		getIsDetailsRules() {
			if (this.form.details !== "") {
				this.rulesMessages = "";
			}
		},

		// 创建人才认证信息（第一次提交）
		firstInformationSubmit(formName) {
			let self = this;
			let address = [];
			if (self.AppendixVerify())
				self.$refs[formName].validate((valid) => {
					console.log(self.form);
					if (self.form.details === "") {
						self.rulesMessages = "请输入详细地址";
					} else {
						self.rulesMessages = "";
						if (valid) {
							address.push({
								province: self.form.addresses[1],
								city: self.form.addresses[2],
								county: self.form.addresses[3],
								details: self.form.details,
								id: 0,
							});
							if (address[0].city === undefined) {
								address = self.form.caseAddress;
							}
							self.form.educations.academic = self.form.academic;
							self.form.educations.school = self.form.school;
							self.form.educations.graduateTime = self.form.graduateTime;
							self.form.educations.schoolSystem = self.form.schoolSystem;
							self.form.educations.major = self.form.major;
							self.form.educations.isStudyAbroad = self.form.isStudyAbroad;
							self.form.talentSecondEducation.push(self.form.educations);

							let personalInformation = {
								addresses: address, // 地址
								age: 0, // 年龄
								birthday: self.form.birthday, // 生日
								birthplace: self.form.birthplace, // 籍贯
								block: self.block, // 住房所在区
								buyHouse: self.form.buyHouse, // 是否买房
								citizenship: self.form.citizenship, // 国籍
								educations: self.form.talentSecondEducation, // 学历信息
								email: self.form.email, //邮箱
								endEffectiveDate: self.form.endEffectiveDate, // 证件有效结束日期
								fileIds: self.form.fileIds, // 文件ID
								hasHouse: self.form.hasHouse, //是否在城区有房
								hasSocialSecurity: self.form.hasSocialSecurity, // 是否有缴纳社保
								honors: self.form.honors, // 所获荣誉
								id: self.form.id,
								identityCode: self.form.identityCode, // 证件号码
								identityType: self.form.identityType, //证件类型
								name: self.form.name, //名字
								nation: self.form.nation, // 民族
								occupation: {
									ownerId: sessionStorage.getItem("ID"),
									enterpriseId: self.form.enterpriseId, //目前供职公司
									position: self.form.position, //所在公司职务
									rankCategory: self.form.value, //职称类别
									hireDate: self.form.hireDate, // 入职时间
									rankLevel: self.form.rankLevel, // 是否省外引进
									skillCategory: self.form.skillCategory, // 从事专业领域
								},
								resume: self.form.resume, // 简历
								resumes: self.form.resumes, // 工作履历
								sex: self.form.sex, // 性别
								socialSecurityPaymentTime: self.form.socialSecurityPaymentTime, //缴社保时间
								startEffectiveDate: self.form.startEffectiveDate, // 证件有效开始时间
								status: 1,
								type: self.form.type, // 人才类型
							};
							console.log(personalInformation);
							this.$axios
								.put("/talent/authentication", personalInformation)
								.then((response) => {
									if (response.data.status === 200) {
										self.isUpdate = true;
										self.getTalentInformation(response.data.data.id, formName);
									} else {
										self.$root.warn("提交失败");
										self.form.addresses.pop();
										self.form.talentSecondEducation.pop();
									}
								})
								.catch((error) => {
									console.error(error);
									self.form.talentSecondEducation.pop();
								});
						} else {
							self.form.talentSecondEducation.pop();
							self.informationStatus = 1;
							setTimeout(() => {
								self.informationStatus = 0;
							}, 3000);
							self.$root.warn("请完善信息");
							return false;
						}
					}
				});
		},

		//  更新人才认证信息
		updateInformationSubmit(formName) {
			let self = this;
			let address = [];
			if (self.AppendixVerify())
				self.$refs[formName].validate((valid) => {
					if (valid) {
						address.push({
							province: self.form.addresses[1],
							city: self.form.addresses[2],
							county: self.form.addresses[3],
							id: self.form.addressId,
							details: self.form.details,
						});
						if (address[0].city === undefined) {
							address = self.form.caseAddress;
							address[0].details = self.form.details;
						}
						self.form.educations.academic = self.form.academic;
						self.form.educations.school = self.form.school;
						self.form.educations.graduateTime = self.form.graduateTime;
						self.form.educations.schoolSystem = self.form.schoolSystem;
						self.form.educations.major = self.form.major;
						self.form.educations.isStudyAbroad = self.form.isStudyAbroad;
						self.form.talentSecondEducation.push(self.form.educations);

						let personalInformation = {
							addresses: address, // 地址
							age: 0, // 年龄
							birthday: self.form.birthday, // 生日
							birthplace: self.form.birthplace, // 籍贯
							block: self.block, // 住房所在区
							buyHouse: self.form.buyHouse, // 是否买房
							citizenship: self.form.citizenship, // 国籍
							educations: self.form.talentSecondEducation, // 学历信息
							email: self.form.email, //邮箱
							endEffectiveDate: self.form.endEffectiveDate, // 证件有效结束日期
							fileIds: self.form.fileIds, // 文件ID
							hasHouse: self.form.hasHouse, //是否在城区有房
							hasSocialSecurity: self.form.hasSocialSecurity, // 是否有缴纳社保
							honors: self.form.honors, // 所获荣誉
							id: self.form.id,
							identityCode: self.form.identityCode, // 证件号码
							identityType: self.form.identityType, //证件类型
							name: self.form.name, //名字
							nation: self.form.nation, // 民族
							occupation: {
								ownerId: sessionStorage.getItem("ID"),
								enterpriseId: self.form.enterpriseId, //目前供职公司
								position: self.form.position, //所在公司职务
								rankCategory: self.form.value, //职称类别
								hireDate: self.form.hireDate, // 入职时间
								id: self.form.occupationId,
								rankLevel: self.form.rankLevel, // 是否省外引进
								skillCategory: self.form.skillCategory, // 从事专业领域
							},
							resume: self.form.resume, // 简历
							resumes: self.form.resumes, // 工作履历
							sex: self.form.sex, // 性别
							socialSecurityPaymentTime: self.form.socialSecurityPaymentTime, //缴社保时间
							startEffectiveDate: self.form.startEffectiveDate, // 证件有效开始时间
							status: 2,
							type: self.form.type, // 人才类型
						};
						//console.log(JSON.stringify(personalInformation));
						this.$axios
							.put("/talent", personalInformation)
							.then(function(res) {
								if (res.data.status === 200) {
									self.$notify({
										title: "成功",
										message: "更新成功",
										type: "success",
									});
									self.$router.push({ path: "/main/talent" });
									self.getTalentInformation();
								} else {
									self.$notify({
										title: "失败",
										message: "更新失败",
										type: "warning",
									});
									self.form.addresses.pop();
									self.form.talentSecondEducation.pop();
								}
							})
							.catch(function(error) {
								console.error(error);

								self.form.talentSecondEducation.pop();
							});
					} else {
						self.form.talentSecondEducation.pop();
						self.informationStatus = 1;
						setTimeout(() => {
							self.informationStatus = 0;
						}, 3000);
						//console.log(valid);
						self.$root.warn("请完善信息");
						return false;
					}
				});
		},

		// 控制图片上传的类型与大小
		beforeAvatarUpload(file) {
			// const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1)

			const isPNG = file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg"; //客户又要求 可以上传 png jpg jpeg
			const isLt2M = file.size / 1024 / 1024 < 5;
			console.log(isPNG);
			if (isPNG) {
			} else {
				this.$message.error("只能是 png jpg jpeg 格式图片!");
				return false;
			}
			if (!isLt2M) {
				this.$message.error("图片大小不能超过 5MB!");
				return false;
			}
		},

		// 控制文件上传的类型与大小
		beforeFileUpload(file) {
			const isPDF = file.type === "application/pdf";
			const isLt2M = file.size / 1024 / 1024 < 7;

			if (isPDF) {
			} else {
				this.$message.error("请上传pdf文件");
				return false;
			}
			if (!isLt2M) {
				this.$message.error("图片大小不能超过 7MB!");
				return false;
			}
		},

		// 添加学历
		getAddEducation() {
			let date = { ownerId: sessionStorage.getItem("ID") };
			this.$axios.post("/talent/education", date).then((response) => {
				if (response.data.status === 200) {
					this.form.talentSecondEducation.push({
						number: "education" + this.form.talentSecondEducation.length,
						academic: "", //  最高学历
						school: "", //毕业学校
						major: "", //专业
						id: response.data.data,
						graduateTime: "", //毕业日期
						diplomaCode: "", //毕业证书编号
						isStudyAbroad: false, // 是否国外学历
						ownerId: sessionStorage.getItem("ID"),
						schoolSystem: "", // 学制
						type: "全日制", // 是否全日制
						sort: this.form.talentSecondEducation.length + 1, // 排序
						/**
						 * 学历证书附件数组
						 */
						xlzs: [],
						/**
						 * 学历电子注册备案表
						 */
						xldzxxbab: [],
						/**
						 * 学位证书
						 */
						xwzs: [],
					});
					if (this.form.talentSecondEducation.length > 4) {
						this.overstepEducation = true;
					}
				}
			});
		},

		//  删除学历
		getDeleteEducation(index, item) {
			if (this.form.talentSecondEducation.length < 6) {
				this.overstepEducation = false;
			}
			this.$axios.delete("/talent/education?id=" + item.id).then((response) => {
				if (response.data.status === 200) {
					this.form.talentSecondEducation.splice(index, 1);
				}
			});
		},

		//  添加所获得的荣誉
		getAddHonor() {
			let date = { talentId: sessionStorage.getItem("ID") };
			this.$axios.post("/honor", date).then((response) => {
				if (response.data.code === 0) {
					this.form.honors.push({
						number: "honors" + this.form.honors.length,
						honorName: "",
						implementationDepartment: "",
						awardYear: "",
						rewardLevel: "",
						shry: [],
						id: response.data.data,
					});
				}
			});
		},

		//  删除所获得的荣誉
		getDeleteHonor(index, item) {
			this.$axios.delete("/honor?idList=" + item.id).then((response) => {
				if (response.data.data === true) {
					this.form.honors.splice(index, 1);
				}
			});
		},

		//  添加工作履历
		getAddWorkingExperience() {
			this.form.resumes.push({
				number: "workingExperience" + this.form.resumes.length,
				companyName: "",
				startDate: "",
				endDate: "",
				jobTitle: "",
				introducePlace: "",
				professionalField: "",
				isAdd: true,
			});
		},

		//  删除工作履历
		getDeleteWorkingExperience(index, item) {
			if (item.isAdd) {
				this.form.resumes.splice(index, 1);
			} else {
				this.$axios.delete("/tResume?idList=" + item.id).then((response) => {
					if (response.data.data === true) {
						this.form.resumes.splice(index, 1);
					}
				});
			}
		},

		//  打开预览提交
		onPreview() {
			this.form.educations.academic = this.form.academic;
			this.form.educations.school = this.form.school;
			this.form.educations.graduateTime = this.form.graduateTime;
			this.form.educations.schoolSystem = this.form.schoolSystem;
			this.form.educations.major = this.form.major;
			this.form.educations.isStudyAbroad = this.form.isStudyAbroad;
			/**
			 * 地址
			 */
			if (this.form.addresses[0] === "中国") {
				this.form.addressDeposit = this.form.addresses[0] + this.form.addresses[1] + this.form.addresses[2] + this.form.addresses[3] + this.form.details;
			} else {
				this.form.addressDeposit = this.form.addressDetails + this.form.details;
			}
			/*
            公司名称
            */
			if (this.form.isEnterprise) {
				this.form.enterpriseName = this.form.enterpriseUserName;
				this.form.isEnterprise = false;
			}
			this.form.block = this.block;
			this.itemAuthentication = this.form;
			this.DialogPreview = true;
		},

		// 关闭预览提交
		closePersonalInformation() {
			this.DialogPreview = false;
		},

		//获取公司信息
		getEnterpriseInformation() {
			let self = this;
			this.$axios
				.post("/enterprise/condition", {
					current: 1,
					size: 1500,
					statuses: [21],
				})
				.then(function(res) {
					if (res.data.status === 200) {
						let cars = res.data.data.records;
						cars.forEach((item) => {
							self.Qiyes.push({
								enterpriseId: item.id,
								label: item.name,
							});
						});
					}
				})
				.catch(function(error) {
					console.error(error);
				});
		},

		// 第二学历是否国外
		studyAbroad22(v) {
			let newfileId = [];
			if (v) {
				if (this.Twoxuelibgfile.length) {
					this.Twoxuelibgfile.forEach((item) => {
						this.$axios.delete("/file/delete?id=" + item.id).then(function(res) {
							if (res.data.status === 200) {
							}
						});
						this.form.fileIds.forEach((jtem) => {
							if (item.id !== jtem) {
								newfileId.push(jtem);
							}
						});
					});
					this.form.fileIds = newfileId;
					this.Twoxuelibgfile = [];
				}
			}
		},

		// 身份证是否被占用
		isIdentityCode(Code) {
			let self = this;
			if (Code && Code.length === 18 && typeof Code == "number") {
				this.$axios
					.get("/talent/check", {
						params: {
							id: this.form.id,
							identityCode: Code,
						},
					})
					.then(function(res) {
						if (res.data.data) {
							self.IsCode = true;
							self.$root.warn("身份证号已被占用，请致电系统管理员");
						} else {
							self.IsCode = false;
						}
					});
			}
		},

		// 移除文件
		handleRemove(file) {
			let self = this;
			console.log(file);
			// this.$axios.delete("/attach?id=" + file.id).then(function(res) {
			//   if (res.data.status === 200) {
			//     self.form.allFile.forEach((item, index) => {
			//       if (item.id === file.id) {
			//         self.form.allFile.splice(index, 1);
			//       }
			//     });
			//   }
			// });
		},
		settab(name) {
			this.tabName = name;
		},
		// 查看照片
		handlePreview(file) {
			console.log(file);

			let pdfFile = file.name.split(".");

			let pdfFilename = file.url ? file.url.split("?") : file.response.data.path.split("?");
			let houzui = pdfFile[1] ? pdfFile[1] : file.url.split("?")[1].split(".")[1];
			console.log(houzui);
			if (houzui === "pdf") {
				//下载链接
				let uesurl = configDate.href + "/file/object?" + pdfFilename[1];
				if (confirm("需要下载吗")) {
					this.getDownloadFile(uesurl);
					//window.open(file.url ||configDate.url + '/file/object/preview?path=' + file.response.data.path,"_blank");
				} else {
					this.oWidth = "70%";
					//window.open(file.url ||configDate.url + '/file/object/preview?path=' + file.response.data.path,"_blank");
					//console.log(file.url ||configDate.url + '/file/object/preview?path=' + file.response.data.path,"_blank")
					this.pisDialog = true;
					this.pdf = file.url || configDate.url + "/file/object?path=" + file.response.data.path;
					this.pispath = "";
				}
			} else {
				this.pisDialog = true;
				this.pdf = "";
				this.oWidth = "600px";
				this.pispath = file.url || configDate.url + "/file/object?path=" + file.response.data.path;
			}
		},

		// 下载pdf文件
		getDownloadFile(item) {
			const link = document.createElement("a");
			link.style.display = "none";

			link.href = item;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			this.pisDialog = false;
		},

		// 限制文件上传个数
		handleExceed(files, fileList) {
			this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
		},
		//暂存
		temporarySubmit() {
			this.setloading(10000, "正在暂存个人信息..."); //预加载动画
			this.temporary = this.unique(this.temporary);
			console.log(this.temporary);
			this.$axios.post("/temporary", this.temporary).then((response) => {
				if (response.data.code === 0) {
					console.log(response.data.msg);
					this.$notify({
						title: "暂存成功！",
						type: "success",
					});
				}
			});
		},
		unique(arr) {
			const res = new Map();
			let Atte = [];
			let Btte = [];
			arr.forEach((item) => {
				if (item.name !== "身份证") {
					Atte.push(item);
				} else {
					Btte.push(item);
				}
			});
			let Oatt = Atte.filter((Atte) => !res.has(Atte.name) && res.set(Atte.name, 1));
			return [...Oatt, ...Btte]; // return arr.filter((arr) => !res.has(arr.name) && res.set(arr.name, 1));
		},
		uniqueFile(arr) {
			const resv = new Map();
			let Atte = [];
			let Btte = [];
			arr.forEach((item) => {
				if (item.fileNaem !== "") {
					Atte.push(item);
				} else {
					Btte.push(item);
				}
			});
			console.log(Btte, Atte);
			let Asbd = Atte.filter((Atte) => !resv.has(Atte.fileNaem) && resv.set(Atte.fileNaem, 1));
			return [...Btte, ...Asbd];
			//     const resv = new Map();
			//     return arr.filter((arr) => arr.fileNaem==""?!resv.has(arr.fileNaem) && resv.set(arr.fileNaem, 1):'');
		},
		testPash(k, v) {
			let self = this;
			console.log(k + "---" + v);
			if (v) {
				if (this.temporary.length > 0) {
					this.temporary.filter(function(number) {
						if (number.name == k) {
							self.temporary.forEach((item) => {
								//console.log(number.name == item.name);
								if (number.name == item.name) {
									item.value = v;
								}
							});
						} else {
							self.temporary.push({
								name: k,
								ownerid: self.form.id,
								value: v,
							});
						}
					});
					return;
				}
				this.temporary.push({
					name: k,
					ownerid: this.form.id,
					value: v,
				});
			}
			//console.log(this.temporary);
		},
		//暂存回显
		getTemporary(status, talentId) {
			var num = 0;
			let self = this;
			if (status === 0) {
				console.log("暂存回显", status, talentId);
				this.$axios.get("/temporary?ownerId=" + talentId).then((response) => {
					//console.log(response.data)
					if (response.data.code === 0) {
						//console.log(response.data)
						self.temporary = response.data.data;
						for (var k in this.form) {
							num++;
							response.data.data.forEach((item) => {
								//console.log(item.name,item.value)
								if (k === item.name) {
									//this.form[k] = item.value

									if (item.name === "sex") {
										// console.log(item.name,item.value)
										self.form[k] = item.value * 1;
									} else if (item.name === "buyHouse") {
										self.form[k] = item.value == "true" ? true : false;
									} else if (item.name === "hasHouse") {
										self.form[k] = item.value == "true" ? true : false;
									} else if (item.name === "enterpriseName") {
										self.form.enterpriseId = item.value;
										self.form[k] = item.value;
									} else {
										self.form[k] = item.value;
									}
								}
								console.log(self.form.enterpriseId);

								switch (item.name) {
									case "免冠工作照":
										self.bareheadedWorkPhotoFile = [
											{
												id: item.id,
												name: item.fileNaem,
												url: configDate.fileurl + item.path,
											},
										];
										break;
									case "身份证":
										// self.IDFile=[{
										//     id: item.id,
										//     name: item.name,
										//     url: configDate.fileurl + item.path,
										// }]
										// console.log(num);
										if (num <= 1) {
											self.IDFile.push({
												id: item.id,
												name: item.fileNaem,
												url: configDate.fileurl + item.path,
											});
											console.log(num);
										}
										// self.IDFile.push({
										//         id: item.id,
										//         name: item.fileNaem,
										//         url: configDate.fileurl + item.path,
										//     });
										break;
									case "最高学历证书":
										self.educationFile = [
											{
												id: item.id,
												name: item.fileNaem,
												url: configDate.fileurl + item.path,
											},
										];
										break;
									case "最高学历电子信息备案表":
										self.informationRecordFormFile = [
											{
												id: item.id,
												name: item.fileNaem,
												url: configDate.fileurl + item.path,
											},
										];
										break;
									case "最高学位证书":
										self.academicDegreeFile = [
											{
												id: item.id,
												name: item.fileNaem,
												url: configDate.fileurl + item.path,
											},
										];
										break;
									case "人才类型附件":
										self.typesTalentsFile = [
											{
												id: item.id,
												name: item.fileNaem,
												url: configDate.fileurl + item.path,
											},
										];
										break;
									case "社保附件":
										self.socialSecurityFile = [
											{
												id: item.id,
												name: item.fileNaem,
												url: configDate.pdffileurl + item.path,
											},
										];
										break;
									case "个人所得税":
										self.incomeTaxFile = [
											{
												id: item.id,
												name: item.fileNaem,
												url: configDate.pdffileurl + item.path,
											},
										];
										break;
									case "劳动合同":
										self.LaborContractFile = [
											{
												id: item.id,
												name: item.fileNaem,
												url: configDate.pdffileurl + item.path,
											},
										];
										break;
								}
							});
						}
					}
				});
			}
		},
		//上传成功
		uploadFile(response) {
			let self = this;
			let _Temporary = self.temporary;
			if (response.status === 200) {
				self.file = response;
				let data = {
					id: response.data.id,
					memo: self.fileLabel,
					ownerId: self.educationsFileId,
				};
				console.log(self.fileLabel === "劳动合同");
				if (self.fileLabel === "身份证") {
					self.IDcardFile = true;
				} else if (self.fileLabel === "社保附件") {
					self.solSecFile = true;
				} else if (self.fileLabel === "劳动合同") {
					self.LaContFile = true;
					console.log(self.LaContFile);
				} else if (self.fileLabel === "最高学历证书") {
					self.eduatFile = true;
				} else if (self.fileLabel === "最高学位证书") {
					self.academictFile = true;
				}
				this.$axios.put("/file/record", data).then(function(res) {
					if (res.data.status === 200) {
						self.isUploadFile = false;
						self.form.allFile.push({
							id: response.data.id,
							memo: self.fileLabel,
							path: response.data.path,
						});

						if (_Temporary.length > 0) {
							_Temporary.forEach((item) => {
								console.log(item.fileNaem);
								if (item.fileNaem) {
									console.log(self.file.data.name, item.fileNaem);
									if (self.file.data.name == item.fileNaem) {
										console.log(item.fileNaem);
										item.value = response.data.id;
										item.fileNaem = self.file.data.name;
									} else {
										//console.log(item.fileNaem);
										_Temporary.push({
											name: self.fileLabel,
											ownerid: self.form.id,
											fileNaem: self.file.data.name,
											value: response.data.id,
											path: response.data.path,
										});
									}
								} else {
									_Temporary.push({
										name: self.fileLabel,
										ownerid: self.form.id,
										fileNaem: self.file.data.name,
										value: response.data.id,
										path: response.data.path,
									});
								}
							});
						} else {
							console.log(response.data);
							_Temporary.push({
								name: self.fileLabel,
								ownerid: self.form.id,
								fileNaem: self.file.data.name,
								value: response.data.id,
								path: response.data.path,
							});
						}

						//暂存附件

						self.temporary = self.uniqueFile(_Temporary);
						console.log(self.temporary);

						if (self.educationsFileId === undefined) {
							self.form.fileIds.push(response.data.id);
						}
					}
				});
			} else {
				self.$notify({
					title: "上传失败",
					message: response.data,
					type: "warning",
				});
				self.isUploadFile = false;
			}
		},

		// 户籍地址
		handleChange(value) {
			this.form.addresses = value;
		},

		// 目前供职公司
		currentSel(selVal) {
			console.log(selVal);
			this.form.enterpriseId = selVal; //目前供职公司
			this.enterpriseId = selVal;
			this.$axios.get("/enterprise?id=" + selVal).then((response) => {
				if (response.data.status === 200) {
					this.form.isEnterprise = true;

					this.form.enterpriseId = response.data.data.id;
					this.form.enterpriseUserName = response.data.data.name;
				}
				console.log(this.form);
			});
		},
		reload() {
			location.reload();
		},
		// 删除文件
		beforeRemove(file, fileList) {
			console.log(fileList);

			this.setloading(3000, "正在删除文件..."); //预加载动画
			let id;
			if (file.id) {
				id = file.id;
			}
			if (file.response) {
				id = file.response.data.id;
			}

			if (this.form.status == 0) {
				//    this.temporary.forEach(list=>{
				//        console.log(list.fileNaem);
				//    })
				for (const key in this.temporary) {
					if (this.temporary[key].id == id) {
						console.log(id);
						this.temporary.splice(key, 1);
					}
				}

				this.$axios.delete("/temporary?idList=" + id).then((response) => {
					console.log(response.data);
					if (response.data.code === 0) {
						this.$notify({
							title: response.data.data ? "删除文件成功" : "",
							type: "success",
						});
					}
				});
				console.log(this.temporary);
				return;
			}
			if (!id) return;
			let newFileId = [];
			this.$axios.delete("/file/delete?id=" + id).then((response) => {
				console.log(response);
				if (response.data.status === 200) {
					let arr = this.form.fileIds.indexOf(id);
					this.form.fileIds.splice(arr, 1);
					let arrFile = this.form.allFile.indexOf(id);
					this.form.allFile.splice(arrFile, 1);

					this.$notify({
						title: response.data.data,
						type: response.data.desc,
					});
					// this.reload()
				} else {
					this.$notify({
						title: "删除文件失败",
						type: "warning",
					});
				}
			});
		},
		setloading(time, text) {
			const loading = this.$loading({
				lock: true,
				text: text,
				spinner: "el-icon-loading",
				background: "rgba(84, 84, 84, 0.21)",
			});
			setTimeout(() => {
				loading.close();
			}, time);
		},
	},
	watch: {
		data: {
			handler(newVal, oldVal) {},
			deep: true,
		},
	},
	mounted() {
		if (this.commonFunction._isMobile()) {
			this.isMobile = true;
		} else {
			this.isMobile = false;
		}
	},
	components: {
		Preview,
	},
};
</script>

<style lang="less" scoped>
button {
	color: #ffffff;
}

.six {
	/deep/ .el-form--label-top .el-form-item__label {
		padding-bottom: 0;
	}

	.el-form-item {
		margin-bottom: 5px;
	}

	.el-form-item__label {
		float: none;
	}
}

.redText {
	font-size: 12px;
	color: #ef5459;
}

.template {
	position: absolute;
	top: -32px;
}

.uploadBtn {
	width: 118px;
	height: 26px;
	padding: 0;
	line-height: 26px;
	text-align: center;
	font-size: 16px;
	color: #888;
	border: 1px solid #c0c0c0;
	background: #fafafa;
	border-radius: 3px;
	outline: none;
}

.upload-demo,
.el-form-item {
	text-align: left;
}

.pisDialog {
	background: url("../../../assets/loading.gif") no-repeat center center;
}

.shuoming {
	margin: auto;
	line-height: 40px;
	font-size: 14px;
	color: #2581d8;
	cursor: pointer;
	width: 140px;
}

@import "../../../styles/common_qyrc.less";
</style>
